import React, {useMemo, useRef, useState} from 'react'
import Link from 'next/link'

import WizButton from '@component/buttons/WizButton'
import UserThumbnail from '@component/images/UserThumbnail'
import ProTag from '@component/tags/ProTag'
import WizImage from '@component/images/WizImage'
import NotRegisteredApiModal from '@feature/portfolio/components/NotRegisteredApiModal/NotRegisteredApiModal'
import Text from '@component/Text/Text'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import useCopy from '@hook/useCopy'
import useNavigation from '@hook/useNavigation'
import useSnackbar from '@hook/useSnackbar'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import useLogin from '@hook/useLogin'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useLoginModalStore} from '@store/LoginModalStore'
import usePortfolio from '@hook/usePortfolio'
import useWindowSize from '@hook/useWindowSize'

import NewIcon from '@svg/common/ic_new.svg'
import IconDollar from '@svg/portfolio/ic_sidebar_dollar.svg'
import IconLeaderEarning from '@svg/portfolio/ic_sidebar_leader_earning.svg'
import IconWalletBalance from '@svg/portfolio/ic_sidebar_wallet.svg'
import IconInvoices from '@svg/portfolio/ic_sidebar_invoice.svg'
import IconDown from '@svg/common/ic_btn_down.svg'
import SidebarBalanceChart from '@feature/portfolio/components/charts/SidebarBalanceChart/SidebarBalanceChart'
import SidebarProfitChart from '@feature/portfolio/components/charts/SidebarProfitChart/SidebarProfitChart'
import {getNumberColorClass} from '@util/etc'
import CreateCopyModal from '@feature/copy/components/CreateCopyModal/CreateCopyModal'
import FollowModal from '@feature/portfolio/components/FollowModal/FollowModal'
import dynamic from 'next/dynamic'
import usePortfolioPriavcy from '@hook/usePortfolioPrivacy'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {DayDuration} from '@type/time'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import {useQueryClient} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import useQueryGetKeySidebar from '@hook/query/portfolio/useQueryGetKeySidebar'
import IconDollarDark from '@svg/portfolio/ic_sidebar_dollar_dark.svg'
import useDark from '@hook/useDark'
import IconLeaderEarningDark from '@svg/portfolio/ic_sidebar_leader_earning_dark.svg'
import IconWalletBalanceDark from '@svg/portfolio/ic_sidebar_wallet_dark.svg'
import IconInvoicesDark from '@svg/portfolio/ic_sidebar_invoice_dark.svg'
import {useIsomorphicLayoutEffect} from 'usehooks-ts'
import {createLog} from '@util/logs'
import {
    getLinkUrl,
    URL_ACCOUNT_API,
    URL_ACCOUNT_INVOICE,
    URL_ACCOUNT_WALLET,
    URL_COPY_PORTFOLIO,
    URL_LEADER_PORTFOLIO,
} from '@constant/url'
import {UserPrivacy} from '@type/profile'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'
import {toast} from 'react-toastify'
import WizToastMessage from '@component/snackbar/WizToastMessage'
import SharePortfolioOptionModal from '@feature/portfolio/components/SharePortfolioOptionModal/SharePortfolioOptionModal'
import SharePortfolioButton from '@feature/portfolio/components/SharePortfolioButton/SharePortfolioButton'
import useQueryGetUserProfile from '@hook/query/portfolio/useQueryGetUserProfile'
import IconExchanges from '@svg/api/ic_exchanges.svg'

const PrivacyNoticeCSR = dynamic(import('@feature/portfolio/components/PrivacyNotice/PrivacyNotice'), {
    ssr: false,
})

interface IProps {
    className?: string
    id: string
    login_id: string
    isMe?: boolean
}

const KeySideBarContainer: React.FC<IProps> = ({className, id, login_id, isMe}) => {
    const router = useRouter()
    const {t} = useTranslation()
    const {isRenderDark} = useDark()
    const queryClient = useQueryClient()

    const {displayBalance, displayPercent, displayNumber, displayApproximateBalance} = useFormatNumber()
    const {displayDateFromText} = useFormatDate()

    const {showSnackbar} = useSnackbar()
    const {copyToClipboard} = useCopy()
    const {goSubscriber} = useNavigation()
    const {followUser} = usePortfolio()
    const {isLg} = useWindowSize()

    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    const [statusMore, setStatusMore] = useState(false)
    const [isCheckStatusHeight, setIsCheckStatusHeight] = useState(false)

    const sharePortfolioOptionModalRef = useRef<any>()
    const notRegisteredApiModalRef = useRef<any>()
    const createCopyModalRef = useRef<any>()
    const followModalRef = useRef<any>()
    const statusRef = useRef<any>()

    const {
        data: sidebar,
        refetch: refreshSidebar,
        isSuccess: isSidebarSuccess,
        isLoading: isSidebarLoading,
        isFetching: isSidebarFetching,
    } = useQueryGetKeySidebar(id, false)
    const {isLogin} = useLogin(sidebar?.user_id)

    const {data: me} = useQueryFetchMe(undefined, false)
    const {
        chartGraphData,
        isLoading: isGraphLoading,
        isFetching: isGraphFetching,
        isSuccess: isGraphSuccess,
    } = useQueryGetUserGraph(sidebar?.user_id, DayDuration.Lifetime)
    const {data: pendingInvoices} = useQueryGetPendingInvoices(true)
    const {refetch: refreshProfile} = useQueryGetUserProfile(sidebar?.user_id, undefined, false)
    const {isHidePortfolio} = usePortfolioPriavcy(sidebar?.privacy_level, sidebar?.is_following, sidebar?.user_id)

    useIsomorphicLayoutEffect(() => {
        if (statusRef?.current?.offsetHeight > 65) {
            setIsCheckStatusHeight(true)
        } else {
            setIsCheckStatusHeight(false)
        }
    }, [statusRef, isLg])

    const onRefresh = async () => {
        await refreshSidebar()
        await refreshProfile()
        await queryClient.refetchQueries(QueryCacheKeys.portfolio.getUserProfile(sidebar?.user_id, router?.locale))
        await queryClient.refetchQueries(QueryCacheKeys.portfolio.getKeyProfile(id))
    }

    const onClickOpenFollowModal = async () => {
        if (!isLogin()) {
            showLoginModal()
            return
        }

        await followUser(sidebar?.user_id, onRefresh)
    }

    const onClickOpenSharePortfolioModal = () => {
        if ((isMe && !me?.keys) || (isMe && me?.keys?.length < 1)) {
            notRegisteredApiModalRef?.current?.show()
            return
        }
        if (isMe) {
            createLog('event', 'key_portfolio_sidebar_open_share_portfolio_option_modal')
            sharePortfolioOptionModalRef?.current?.show()
        } else {
            createLog('event', 'key_portfolio_sidebar_share_portfolio_copy_link')
            copyToClipboard(`${window.location.origin}/${router.locale}${router?.asPath}`)
            toast(<WizToastMessage text={t('common.copied')} />)
        }
    }

    const isRenderPrivacyIcon = useMemo(() => {
        if (sidebar?.privacy_level === UserPrivacy.OpenToSubscriber && !sidebar?.is_following) {
            return true
        }
        if (
            sidebar?.privacy_level === UserPrivacy.Private ||
            sidebar?.privacy_level === UserPrivacy.CompletelyPrivate
        ) {
            return true
        }
        return false
    }, [sidebar?.privacy_level, sidebar?.is_following])

    const isFetchingSidebar = useMemo(() => {
        return isSidebarFetching && isSidebarLoading && !isSidebarSuccess
    }, [isSidebarLoading, isSidebarFetching, isSidebarSuccess])

    const isFetchingGraph = useMemo(() => {
        return isGraphLoading || (isGraphFetching && !isGraphSuccess)
    }, [isGraphFetching, isGraphLoading, isGraphSuccess])

    return (
        <div className={'flex flex-col items-center mt-6'}>
            <div className={'relative'}>
                {isFetchingSidebar ? (
                    <SkeletonBlock className={'w-[50px] h-[50px]'} sizeClassName={'h-[50px]'} />
                ) : (
                    <UserThumbnail
                        src={sidebar?.thumbnail}
                        size={50}
                        containerClassName={'w-[50px]'}
                        isPro={sidebar?.is_pro}
                    />
                )}
                {sidebar?.is_pro && <ProTag className={'absolute bottom-[-6px] right-[-6px]'} size={'small'} />}
            </div>
            <div className={' mt-[10px]'}>
                {isFetchingSidebar ? (
                    <SkeletonBlock className={'w-[100px] h-[24px] mx-auto'} sizeClassName={'h-[16px]'} />
                ) : (
                    <div className={'text-center'}>
                        <Text className={'text-ti2 break-all inline text-gray01 dark:text-dark_gray01'}>
                            {sidebar?.username}
                        </Text>
                        {sidebar?.flags?.length > 0 && (
                            <WizImage
                                src={sidebar?.flags[0]}
                                width={16}
                                height={16}
                                containerClassName={'rounded-full overflow-hidden inline-block align-middle ml-[3px]'}
                            />
                        )}
                    </div>
                )}
            </div>
            <div>
                <SharePortfolioButton
                    text={t('portfolio.sidebar.share.portfolio.short')}
                    onClick={onClickOpenSharePortfolioModal}
                    size={'small'}
                    containerClassName={'!mt-[5px]'}
                />
                {isMe && (
                    <Link
                        href={getLinkUrl(URL_ACCOUNT_API)}
                        className={
                            'gap-x-[5px] py-[7px] px-[15px] rounded-[20px] mx-auto flex items-center mt-[5px] justify-center bg-primary dark:bg-dark_primary hover:bg-primary_shade dark:hover:bg-dark_primary_shade ring-[1px] !ring-transparent'
                        }>
                        <IconExchanges className={'w-[12px] fill-white'} />
                        <Text className={'text-body3 text-white'}>{t('portfolio.sidebar.api.button')}</Text>
                    </Link>
                )}
            </div>
            <div className={'flex space-x-[10px] mt-[10px]'}>
                <button
                    className={'text-center'}
                    disabled={!isMe}
                    onClick={() => {
                        createLog('event', 'key_portfolio_sidebar_click_following')
                        goSubscriber('following')
                    }}>
                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                        {t('portfolio.sidebar.subscribing.title')}
                    </Text>
                    {isFetchingSidebar ? (
                        <SkeletonBlock className={'w-[50px] h-[24px]'} sizeClassName={'h-[16px]'} />
                    ) : (
                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                            {displayNumber(sidebar?.following)}
                        </Text>
                    )}
                </button>
                <button
                    className={'text-center'}
                    disabled={!isMe}
                    onClick={() => {
                        createLog('event', 'key_portfolio_sidebar_click_followers')
                        goSubscriber('followers')
                    }}>
                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                        {t('portfolio.sidebar.subscribers.title')}
                    </Text>
                    {isFetchingSidebar ? (
                        <SkeletonBlock className={'w-[50px] h-[24px]'} sizeClassName={'h-[16px]'} />
                    ) : (
                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                            {displayNumber(sidebar?.followers)}
                        </Text>
                    )}
                </button>
            </div>

            {!isMe && (
                <div className={'w-full mt-[10px]'}>
                    {(sidebar?.is_following ?? false) === false && (
                        <WizButton
                            className={'w-full'}
                            textClassName={'text-gray02 dark:text-dark_gray02'}
                            buttonType={'border'}
                            size={'small'}
                            text={t('portfolio.sidebar.subscribe.title')}
                            onClick={onClickOpenFollowModal}
                        />
                    )}
                    {sidebar?.is_following === true && (
                        <WizButton
                            className={'w-full'}
                            textClassName={'text-gray02 dark:text-dark_gray02'}
                            buttonType={'border'}
                            size={'small'}
                            text={t('portfolio.sidebar.unsubscribe.title')}
                            onClick={() => {
                                if (!isLogin()) {
                                    showLoginModal()
                                    return
                                }
                                createLog('event', 'key_portfolio_sidebar_open_follow_modal')
                                followModalRef.current.show()
                            }}
                        />
                    )}
                    <WizButton
                        className={'w-full mt-[10px]'}
                        buttonType={'secondary'}
                        size={'small'}
                        disabled={sidebar?.can_copy === false}
                        text={t('portfolio.sidebar.copy.title')}
                        onClick={() => {
                            if (!isLogin()) {
                                showLoginModal()
                                return
                            }
                            createLog('event', 'key_portfolio_sidebar_open_copy_modal')
                            createCopyModalRef.current.show()
                        }}
                    />
                </div>
            )}

            {isFetchingSidebar ? (
                <div className={'space-y-[5px] mt-[20px] h-[65px]'}>
                    <SkeletonBlock className={'w-[200px] h-[21px]'} sizeClassName={'h-[14px]'} />
                    <SkeletonBlock className={'w-[200px] h-[21px]'} sizeClassName={'h-[14px]'} />
                    <SkeletonBlock className={'w-[50%] h-[21px]'} sizeClassName={'h-[14px]'} />
                </div>
            ) : (
                <>
                    <div
                        className={`${statusMore ? 'h-[100%]' : 'h-[65px]'} ${
                            sidebar?.status?.length === 0 && 'hidden'
                        } w-full overflow-hidden mt-[20px]`}>
                        <div ref={statusRef}>
                            <Text
                                className={` text-body2 text-gray02 dark:text-dark_gray02 break-all`}
                                enableHyperlink={true}>
                                {sidebar?.status}
                            </Text>
                        </div>
                    </div>
                    {isCheckStatusHeight && (
                        <div
                            onClick={() => {
                                createLog('event', 'key_portfolio_sidebar_click_status_more')
                                setStatusMore(prev => !prev)
                            }}
                            className={'flex items-center justify-center cursor-pointer pb-[10px] w-full'}>
                            <Text className={`text-gray02 dark:text-dark_gray02 text-ti3`}>
                                {t('portfolio.sidebar.status.more')}
                            </Text>
                            <IconDown
                                className={`w-[10px] fill-gray02 dark:fill-dark_gray02 ${statusMore && 'rotate-180'}`}
                            />
                        </div>
                    )}
                </>
            )}

            <div
                className={`bg-white dark:bg-bg_dark_white01 w-full ${
                    isCheckStatusHeight ? 'mt-[20px]' : 'mt-[30px]'
                } p-[15px] rounded-[5px] shadow`}>
                <Text className={'text-ti4 text-gray03 dark:text-dark_gray03 text-center'}>
                    {t('portfolio.sidebar.summary.portfolioBalance')}
                </Text>
                {isFetchingSidebar ? (
                    <SkeletonBlock className={'w-[140px] h-[21px] mx-auto'} sizeClassName={'h-[14px]'} />
                ) : (
                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 text-center'}>
                        {isHidePortfolio
                            ? displayApproximateBalance(sidebar?.current_balance ?? 0, {
                                  showPreSign: true,
                              })
                            : displayBalance(sidebar?.current_balance ?? 0, {
                                  showPreSign: true,
                              })}
                    </Text>
                )}

                <SidebarBalanceChart height={45} input={chartGraphData} isFetching={isFetchingGraph} />

                <Text className={'text-ti4 text-gray03 dark:text-dark_gray03 text-center mt-[30px] '}>
                    {t('portfolio.sidebar.summary.allTimeProfit')}
                </Text>
                {isFetchingSidebar ? (
                    <SkeletonBlock className={'w-[140px] h-[21px] mx-auto'} sizeClassName={'h-[14px]'} />
                ) : (
                    <Text className={`text-ti2 text-center ${getNumberColorClass(sidebar?.all_time_profit_rate)}`}>
                        {displayPercent(sidebar?.all_time_profit_rate ?? 0)}
                    </Text>
                )}

                <SidebarProfitChart height={45} input={chartGraphData} isFetching={isFetchingGraph} />

                {isMe && (
                    <>
                        <Link
                            href={getLinkUrl(URL_COPY_PORTFOLIO(login_id))}
                            onClick={() => createLog('event', 'key_portfolio_sidebar_click_copy_earning')}
                            className={
                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-[10px] py-[10px]'
                            }>
                            <div className={'flex space-x-[10px] items-center'}>
                                {isRenderDark ? <IconDollarDark /> : <IconDollar />}
                                <div>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.summary.copyEarning')}
                                    </Text>
                                    {isFetchingSidebar ? (
                                        <SkeletonBlock
                                            className={'w-[75px] h-[21px] mx-auto'}
                                            sizeClassName={'h-[14px]'}
                                        />
                                    ) : (
                                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                            {displayBalance(sidebar?.copy_earning, {showPreSign: true})}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </Link>
                        <Link
                            href={getLinkUrl(URL_LEADER_PORTFOLIO(login_id))}
                            onClick={() => createLog('event', 'key_portfolio_sidebar_click_leader_earning')}
                            className={
                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-[5px] py-[10px]'
                            }>
                            <div className={'flex space-x-[10px] items-center'}>
                                {isRenderDark ? <IconLeaderEarningDark /> : <IconLeaderEarning />}
                                <div>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.summary.leaderEarning')}
                                    </Text>
                                    {isFetchingSidebar ? (
                                        <SkeletonBlock
                                            className={'w-[75px] h-[21px] mx-auto'}
                                            sizeClassName={'h-[14px]'}
                                        />
                                    ) : (
                                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                            {displayBalance(me?.leader_earning, {
                                                showPreSign: true,
                                            })}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </Link>

                        <Link
                            href={getLinkUrl(URL_ACCOUNT_WALLET)}
                            onClick={() => createLog('event', 'key_portfolio_sidebar_click_total_value')}
                            className={
                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-[5px] py-[10px]'
                            }>
                            <div className={'flex space-x-[10px] items-center'}>
                                {isRenderDark ? <IconWalletBalanceDark /> : <IconWalletBalance />}
                                <div>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.summary.walletBalance')}
                                    </Text>
                                    {isFetchingSidebar ? (
                                        <SkeletonBlock
                                            className={'w-[75px] h-[21px] mx-auto'}
                                            sizeClassName={'h-[14px]'}
                                        />
                                    ) : (
                                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                            {displayBalance(me?.wallets?.total_value, {
                                                showPreSign: true,
                                            })}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </Link>
                        <Link
                            href={getLinkUrl(URL_ACCOUNT_INVOICE)}
                            onClick={() => createLog('event', 'key_portfolio_sidebar_click_pending_invoice')}
                            className={
                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-[5px] py-[10px]'
                            }>
                            <div className={'flex space-x-[10px] items-center'}>
                                {isRenderDark ? <IconInvoicesDark /> : <IconInvoices />}
                                <div>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.summary.invoice')}
                                    </Text>
                                    {isFetchingSidebar ? (
                                        <SkeletonBlock
                                            className={'w-[75px] h-[21px] mx-auto'}
                                            sizeClassName={'h-[14px]'}
                                        />
                                    ) : (
                                        <div className={'flex items-center'}>
                                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 underline'}>
                                                {displayNumber(pendingInvoices?.length)}
                                            </Text>
                                            {Number(pendingInvoices?.length) > 0 && (
                                                <NewIcon
                                                    width={16}
                                                    height={16}
                                                    className={'w-[16px] h-[16px] fill-red dark:fill-dark_red'}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                    </>
                )}
            </div>

            <div className={'bg-white dark:bg-bg_dark_white01 w-full mt-[20px] p-[15px] rounded-[5px] shadow'}>
                <div className={'flex justify-between'}>
                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 text-center'}>
                        {t('portfolio.sidebar.summary.joined')}
                    </Text>
                    {isFetchingSidebar ? (
                        <SkeletonBlock className={'w-[75px] h-[18px] mx-auto'} sizeClassName={'h-[12px]'} />
                    ) : (
                        <Text className={'text-body3 text-gray01 dark:text-dark_gray01 text-center'}>
                            {displayDateFromText(sidebar?.joined_at, t('dateFormat.date'))}
                        </Text>
                    )}
                </div>
                <div className={'flex justify-between mt-[7px]'}>
                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 text-center'}>
                        {t('portfolio.sidebar.summary.views')}
                    </Text>
                    {isFetchingSidebar ? (
                        <SkeletonBlock className={'w-[75px] h-[18px] mx-auto'} sizeClassName={'h-[12px]'} />
                    ) : (
                        <Text className={'text-body3 text-gray01 dark:text-dark_gray01 text-center'}>
                            {displayNumber(sidebar?.views)}
                        </Text>
                    )}
                </div>
            </div>

            <PrivacyNoticeCSR
                is_following={sidebar?.is_following}
                privacy_level={sidebar?.privacy_level}
                login_id={login_id}
                id={sidebar?.user_id}
                onClickFollow={onClickOpenFollowModal}
            />
            <CreateCopyModal ref={createCopyModalRef} leaderUserId={sidebar?.user_id} />
            <FollowModal
                ref={followModalRef}
                user_id={sidebar?.user_id}
                isFollowing={sidebar?.is_following}
                refreshCallback={onRefresh}
            />
            {isMe && (
                <>
                    <SharePortfolioOptionModal ref={sharePortfolioOptionModalRef} />
                    <NotRegisteredApiModal ref={notRegisteredApiModalRef} />
                </>
            )}
        </div>
    )
}
export default React.memo(KeySideBarContainer)
