import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react'
import WizContentModal from '@component/modal/WizContentModal'
import {useTranslation} from 'next-i18next'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import SidebarBalanceChart from '@feature/portfolio/components/charts/SidebarBalanceChart/SidebarBalanceChart'
import {getNumberColorClass} from '@util/etc'
import SidebarProfitChart from '@feature/portfolio/components/charts/SidebarProfitChart/SidebarProfitChart'
import Link from 'next/link'
import IconDollar from '@svg/portfolio/ic_sidebar_dollar.svg'
import IconLeaderEarning from '@svg/portfolio/ic_sidebar_leader_earning.svg'
import IconWalletBalance from '@svg/portfolio/ic_sidebar_wallet.svg'
import IconInvoices from '@svg/portfolio/ic_sidebar_invoice.svg'
import NewIcon from '@svg/common/ic_new.svg'
import {ISidebar} from '@api/user/sidebar/getSidebar'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import useNavigation from '@hook/useNavigation'
import SharePortfolioModal from '@feature/portfolio/components/SharePortfolioModal/SharePortfolioModal'
import NotRegisteredApiModal from '@feature/portfolio/components/NotRegisteredApiModal/NotRegisteredApiModal'
import {useRouter} from 'next/router'
import useCopy from '@hook/useCopy'
import SharePortfolioButton from '@feature/portfolio/components/SharePortfolioButton/SharePortfolioButton'
import usePortfolioPriavcy from '@hook/usePortfolioPrivacy'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {DayDuration} from '@type/time'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import useLogin from '@hook/useLogin'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import IconDollarDark from '@svg/portfolio/ic_sidebar_dollar_dark.svg'
import useDark from '@hook/useDark'
import IconLeaderEarningDark from '@svg/portfolio/ic_sidebar_leader_earning_dark.svg'
import IconWalletBalanceDark from '@svg/portfolio/ic_sidebar_wallet_dark.svg'
import IconInvoicesDark from '@svg/portfolio/ic_sidebar_invoice_dark.svg'
import {createLog} from '@util/logs'
import {
    getLinkUrl,
    URL_ACCOUNT_API,
    URL_ACCOUNT_INVOICE,
    URL_ACCOUNT_WALLET,
    URL_COPY_PORTFOLIO,
    URL_LEADER_PORTFOLIO,
} from '@constant/url'
import {toast} from 'react-toastify'
import WizToastMessage from '@component/snackbar/WizToastMessage'
import SharePortfolioOptionModal from '@feature/portfolio/components/SharePortfolioOptionModal/SharePortfolioOptionModal'
import IconDown from '@svg/common/ic_btn_down.svg'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'
import IconExchanges from '@svg/api/ic_exchanges.svg'

interface IProps {
    className?: string
    sidebar: ISidebar
}

const MobileSidebarModal = ({className, sidebar}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const shareModalRef = useRef<any>()
    const notRegisteredApiModalRef = useRef<any>()
    const statusRef = useRef<any>()
    const sharePortfolioOptionModalRef = useRef<any>()

    const [statusMore, setStatusMore] = useState(false)
    const [isCheckStatusHeight, setIsCheckStatusHeight] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const {t} = useTranslation()
    const {displayNumber, displayBalance, displayPercent, displayApproximateBalance} = useFormatNumber()
    const {displayDateFromText} = useFormatDate()
    const {goSubscriber} = useNavigation()
    const router = useRouter()
    const {copyToClipboard} = useCopy()
    const {isRenderDark} = useDark()
    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
            setTimeout(() => {
                setIsOpen(true)
            }, 700)
        },
    }))

    const {data: pendingInvoices} = useQueryGetPendingInvoices(false)
    const {chartGraphData} = useQueryGetUserGraph(sidebar?.user_id, DayDuration.Lifetime, false)
    const {isHidePortfolio} = usePortfolioPriavcy(sidebar?.privacy_level, sidebar?.is_following, sidebar?.user_id)
    const {data: me} = useQueryFetchMe(undefined, false)
    const {isMe} = useLogin(sidebar?.user_id)

    useLayoutEffect(() => {
        if (sidebar?.status && isOpen) {
            if (statusRef?.current?.offsetHeight > 65) {
                setIsCheckStatusHeight(true)
            } else {
                setIsCheckStatusHeight(false)
            }
        }
    }, [statusRef, isOpen, sidebar?.status])

    const onClickOpenSharePortfolioModal = () => {
        if ((isMe && !me?.keys) || (isMe && me?.keys?.length < 1)) {
            notRegisteredApiModalRef?.current?.show()
            return
        }
        if (isMe) {
            createLog('event', 'key_portfolio_sidebar_open_share_portfolio_option_modal')
            sharePortfolioOptionModalRef?.current?.show()
        } else {
            createLog('event', 'key_portfolio_sidebar_share_portfolio_copy_link')
            copyToClipboard(`${window.location.origin}/${router.locale}${router?.asPath}`)
            toast(<WizToastMessage text={t('common.copied')} />)
        }
    }

    return (
        <>
            <WizContentModal size={'400'} ref={modalRef}>
                <div className={`${className}`}>
                    <div className={'w-full'}>
                        <div className={'flex flex-col items-center'}>
                            <UserThumbnail src={sidebar?.thumbnail} size={50} containerClassName={'w-[50px]'} />
                            <div className={'flex space-x-[5px] items-center text-center mt-[10px]'}>
                                <Text className={'text-ti2 break-all inline text-gray01 dark:text-dark_gray01'}>
                                    {sidebar?.username}
                                </Text>
                                {sidebar?.flags?.length > 0 && (
                                    <WizImage
                                        src={sidebar?.flags[0]}
                                        width={16}
                                        height={16}
                                        containerClassName={
                                            'rounded-full overflow-hidden inline-block align-middle ml-[3px]'
                                        }
                                    />
                                )}
                            </div>
                            <div>
                                <SharePortfolioButton
                                    text={t('portfolio.sidebar.share.portfolio.short')}
                                    onClick={onClickOpenSharePortfolioModal}
                                />
                                {isMe && (
                                    <Link
                                        href={getLinkUrl(URL_ACCOUNT_API)}
                                        className={
                                            'gap-x-[5px] py-[10px] px-[15px] rounded-[20px] mx-auto flex items-center mt-[5px] justify-center bg-primary dark:bg-dark_primary hover:bg-primary_shade dark:hover:bg-dark_primary_shade ring-[1px] !ring-transparent'
                                        }>
                                        <IconExchanges className={'w-[12px] fill-white'} />
                                        <Text className={'text-body3 text-white'}>
                                            {t('portfolio.sidebar.api.button')}
                                        </Text>
                                    </Link>
                                )}
                            </div>
                            <div className={'flex space-x-[10px] mt-[10px]'}>
                                <button
                                    className={'text-center'}
                                    onClick={() => {
                                        createLog('event', 'portfolio_sidebar_modal_click_following')
                                        goSubscriber('following')
                                    }}>
                                    <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.subscribing.title')}
                                    </Text>
                                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                        {displayNumber(sidebar?.following)}
                                    </Text>
                                </button>
                                <button
                                    className={'text-center'}
                                    onClick={() => {
                                        createLog('event', 'portfolio_sidebar_modal_click_followers')
                                        goSubscriber('followers')
                                    }}>
                                    <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.subscribers.title')}
                                    </Text>
                                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                        {displayNumber(sidebar?.followers)}
                                    </Text>
                                </button>
                            </div>

                            {!isOpen ? (
                                <div className={'space-y-[5px] mt-[20px] h-[65px]'}>
                                    <SkeletonBlock className={'w-[200px] h-[21px]'} sizeClassName={'h-[14px]'} />
                                    <SkeletonBlock className={'w-[200px] h-[21px]'} sizeClassName={'h-[14px]'} />
                                    <SkeletonBlock className={'w-[50%] h-[21px]'} sizeClassName={'h-[14px]'} />
                                </div>
                            ) : (
                                <>
                                    <div
                                        className={`${statusMore ? 'h-[100%]' : 'h-[65px]'} ${
                                            sidebar?.status?.length === 0 && 'hidden'
                                        } w-full overflow-hidden mt-[20px]`}>
                                        <div ref={statusRef}>
                                            <Text
                                                className={`text-center text-body2 text-gray02 dark:text-dark_gray02 break-all`}
                                                enableHyperlink={true}>
                                                {sidebar?.status}
                                            </Text>
                                        </div>
                                    </div>
                                    {isCheckStatusHeight && (
                                        <div
                                            onClick={() => {
                                                createLog('event', 'key_portfolio_sidebar_click_status_more')
                                                setStatusMore(prev => !prev)
                                            }}
                                            className={
                                                'flex items-center justify-center cursor-pointer py-[3px] w-full'
                                            }>
                                            <Text className={`text-gray02 dark:text-dark_gray02 text-ti3`}>
                                                {t('portfolio.sidebar.status.more')}
                                            </Text>
                                            <IconDown
                                                className={`w-[10px] fill-gray02 dark:fill-dark_gray02 ${
                                                    statusMore && 'rotate-180'
                                                }`}
                                            />
                                        </div>
                                    )}
                                </>
                            )}

                            <div
                                className={`bg-white dark:bg-bg_dark_white01 w-full ${
                                    isCheckStatusHeight ? 'mt-[20px]' : 'mt-[30px]'
                                } p-[15px]`}>
                                <Text className={'text-ti4 text-gray03 dark:text-dark_gray03 text-center'}>
                                    {t('portfolio.sidebar.summary.portfolioBalance')}
                                </Text>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 text-center'}>
                                    {isHidePortfolio
                                        ? displayApproximateBalance(sidebar?.current_balance ?? 0, {
                                              showPreSign: true,
                                          })
                                        : displayBalance(sidebar?.current_balance ?? 0, {
                                              showPreSign: true,
                                          })}
                                </Text>

                                <SidebarBalanceChart height={50} input={chartGraphData} />

                                <Text className={'text-ti4 text-gray03 dark:text-dark_gray03 text-center mt-[30px] '}>
                                    {t('portfolio.sidebar.summary.allTimeProfit')}
                                </Text>
                                <Text
                                    className={`text-ti2 text-center ${getNumberColorClass(
                                        sidebar?.all_time_profit_rate,
                                    )}`}>
                                    {displayPercent(sidebar?.all_time_profit_rate ?? 0)}
                                </Text>

                                <SidebarProfitChart height={50} input={chartGraphData} />

                                {isMe && (
                                    <>
                                        <Link
                                            href={getLinkUrl(URL_COPY_PORTFOLIO(sidebar?.user_id))}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_copy_earning')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-[20px] py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconDollarDark /> : <IconDollar />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.copyEarning')}
                                                    </Text>
                                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                                        {displayBalance(sidebar?.copy_earning, {showPreSign: true})}
                                                    </Text>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            href={getLinkUrl(URL_LEADER_PORTFOLIO(sidebar?.user_id))}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_leader_earning')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-2 py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconLeaderEarningDark /> : <IconLeaderEarning />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.leaderEarning')}
                                                    </Text>
                                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                                        {displayBalance(me?.leader_earning, {
                                                            showPreSign: true,
                                                        })}
                                                    </Text>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link
                                            href={getLinkUrl(URL_ACCOUNT_WALLET)}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_total_value')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-2 py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconWalletBalanceDark /> : <IconWalletBalance />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.walletBalance')}
                                                    </Text>
                                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                                        {displayBalance(me?.wallets?.total_value, {
                                                            showPreSign: true,
                                                        })}
                                                    </Text>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            href={getLinkUrl(URL_ACCOUNT_INVOICE)}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_pending_invoice')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-2 py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconInvoicesDark /> : <IconInvoices />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.invoice')}
                                                    </Text>
                                                    <div className={'flex gap-x-[5px] items-center'}>
                                                        <Text
                                                            className={
                                                                'text-ti2 text-gray01 dark:text-dark_gray01 underline'
                                                            }>
                                                            {displayNumber(pendingInvoices?.length)}
                                                        </Text>
                                                        {Number(pendingInvoices?.length) > 0 && (
                                                            <NewIcon
                                                                width={16}
                                                                height={16}
                                                                className={
                                                                    'w-[16px] h-[16px] fill-red dark:fill-dark_red'
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                )}
                            </div>

                            <div className={'bg-white dark:bg-bg_dark_white01 w-full mt-[20px] p-[15px]'}>
                                <div className={'flex justify-between'}>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 text-center'}>
                                        {t('portfolio.sidebar.summary.joined')}
                                    </Text>
                                    <Text className={'text-body3 text-gray01 dark:text-dark_gray01 text-center'}>
                                        {displayDateFromText(sidebar?.joined_at, t('dateFormat.date'))}
                                    </Text>
                                </div>
                                <div className={'flex justify-between mt-[7px]'}>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 text-center'}>
                                        {t('portfolio.sidebar.summary.views')}
                                    </Text>
                                    <Text className={'text-body3 text-gray01 dark:text-dark_gray01 text-center'}>
                                        {displayNumber(sidebar?.views)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WizContentModal>
            <SharePortfolioModal ref={shareModalRef} />
            <NotRegisteredApiModal ref={notRegisteredApiModalRef} />
            {isMe && (
                <>
                    <SharePortfolioOptionModal
                        ref={sharePortfolioOptionModalRef}
                        onCloseCallback={() => {
                            modalRef?.current?.close()
                        }}
                    />
                </>
            )}
        </>
    )
}

export default React.forwardRef(MobileSidebarModal)
